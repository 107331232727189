@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  width: 100%;
  box-sizing: border-box;
  padding: 0;
}

.header {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;

  padding: 12px 16px;
  background-color: $black6;
  align-items: center;
  border-bottom: 1px solid $black10;
  border-top: 1px solid $black10;

  z-index: 10;

  .column {
    max-width: 65px;
    @include body-text(sm, 500);
    color: $black60;
    line-height: 14px;
  }

  .webHeaderColumn {
    display: none;
  }
}

.content {
  padding: 0 16px;

  .subtitle {
    @include body-text(md);
    padding: 10px 0;
    border-bottom: 1px solid $black10;
  }

  .rowContainer {
    padding: 24px 0;
    border-bottom: 1px solid $black10;

    &:last-child {
      border-bottom: 0;
    }

    .rowHeading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $black80;
      margin-bottom: 8px;

      @include body-text(sm, 500);

      .saveAmount {
        color: $darkGreen;
      }
    }

    .rowContent {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-items: center;
      align-items: center;

      .webContentColumn {
        display: none;
      }

      .tableCol {
        color: $black60;
        width: 65px;
        text-align: center;
        @include body-text(sm);

        &.savings {
          display: none;
          color: $deepPurple;
          font-weight: $semiBoldFontWeight;
        }
      }
    }

    .group {
      justify-self: flex-start;
      font-size: 12px;
      display: flex;
      flex: 1;
      min-width: 0;
      align-items: center;

      .checkbox {
        z-index: 1;
      }

      .staticCheckbox {
        display: flex;
        align-items: center;
        margin-right: 8px;

        .wontConsolidate {
          visibility: hidden;
        }

        svg {
          width: 12px;
          height: 12px;

          path {
            stroke: $green;
          }
        }
      }
    }

    .groupFullWidth {
      max-width: 100%;
      width: 100%;
    }
  }
}

.creditorName {
  margin-right: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  text-transform: capitalize;
}

// WEB VERSION

.container {
  @include sm {
    .header {
      grid-template-columns: 2fr repeat(5, 1fr);
    }

    .rowContent {
      width: 100%;
      grid-template-columns: 161.68px repeat(5, minmax(0, 1fr)) !important;
    }

    .webHeaderColumn {
      display: block;
    }

    .webContentColumn {
      display: flex !important;
    }

    .rowHeading {
      display: none !important;
    }

    .group {
      max-width: 100% !important;
    }

    .savings {
      display: block !important;
    }
  }
}
