@import 'src/styles/mixins';
@import 'src/styles/variables';

.container {
  margin: 0 auto;
}

.formContainer {
  position: relative;

  > div {
    margin-top: 250px;
  }
}

.lockOpen {
  align-self: center;
  position: absolute;
  top: 30px;
}

.tableContainer {
  margin-top: 16px;
}

.buttonContainer {
  @include stepsContainer;
  background: $white;
  padding: 20px;
  border-radius: 24px;
  margin-top: 16px !important;

  .buttonMarginTop {
    margin-top: 20px;
  }
}
