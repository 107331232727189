@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 100;
  border-radius: 24px 24px 0 0;
  background-color: $white;
  width: 100%;
  box-sizing: border-box;
  padding-left: 16px;
  display: none;
  transform: translate(-50%, 0);

  @include sm {
    width: 450px;
  }
}

.theme__blue,
.theme__dark,
.theme__purple {
  .headerTitle {
    color: $black100;
  }

  .inputWrapper input {
    background-color: $black6 !important;
  }

  .copiedLabel {
    color: $black100;
  }
}

.header {
  margin: 24px 16px 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  font-weight: $boldFontWeight;
}

.headerIcon {
  path {
    fill: $black100;
  }
}

.waitlistLabel {
  @include body-text('md');
  color: $black80;
  margin-bottom: 24px;
}

.content {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 10px;
  margin-left: -16px;

  // Firebox support
  scrollbar-width: 5px;
  scrollbar-color: $black10;

  svg {
    width: 48px;
    height: 48px;
  }
}

.content::-webkit-scrollbar {
  height: 5px;
}

.content::-webkit-scrollbar-thumb:horizontal {
  background: $black10;
  border-radius: 10px;
}

.socialButton {
  margin: 0 21px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:first-child {
    margin-left: 16px;
  }

  &:last-child {
    padding-right: 16px;
    margin-right: 0;
  }
}

.socialButton > a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  & .iconLabel {
    margin-top: 8px;
  }
}

.socialIcon {
  border-radius: 50%;
  background-color: $white;
}

.iconLabel {
  @include body-text('sm');
  color: $black80;
}

.inputWrapper {
  margin: 24px 24px 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyIcon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: 16px;
}

.copiedWrapper {
  background-color: #e4fff5;
  margin: 24px 16px 24px 0;
  display: flex;
  place-items: center;
  padding: 13px 12px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  background: $green;
  border-radius: 50%;
  padding: 2px;

  path {
    stroke: $white;
  }
}

.copiedLabel {
  @include body-text('md', 400);
  margin-left: 16px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.show {
  animation: showModal 300ms ease-out;
}

.hide {
  animation: hideModal 300ms ease-in;
}

@keyframes showModal {
  0% {
    transform: translate(-50%, 100%);
  }

  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes hideModal {
  0% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(-50%, 100%);
  }
}
