$poppinsFontStyle: 'Poppins', sans-serif;

$regularFontWeight: 400;
$mediumFontWeight: 500;
$semiBoldFontWeight: 600;
$boldFontWeight: 700;

$contentIndex: 2;
$inputDropdownIndex: 9;
$pageLoaderIndex: 10;

$white: #ffffff;
$black3: #f9fafc;
$black6: #f2f4f7;
$black10: #e2e5ec;
$black20: #c5cbd4;
$black40: #a1aab4;
$black60: #6d7784;
$black80: #454b54;
$black100: #1a1e23;
$blue: #0d95f7;
$deepBlue: #2147c9;
$blue300: #7d96e8;
$blue500: #2b46c2;
$blueLight: #e7f0fe;
$darkBlue: #0c88e1;
$turquoise: #42cfee;
$turquoiseLight: #e4fafd;
$violet: #9d86f9;
$red: #ff5a64;
$green: #42dca4;
$greenLight: #eaf8e5;
$darkGreen: #19865f;
$deepPurple: #795af7;
$deepPurpleLight: #b8a9f4;
$grayLight: #e7f0fe;
$irisLight: #e8e8fd;
$yellowLight: #fff8cd;

$purple500: #6d2fd0;
$purple300: mix($purple500, $white, $weight: 70%);

$violetGradient: linear-gradient(0deg, $deepPurple, $deepPurple), linear-gradient(90deg, #588dfd 0%, #6251e5 100%);

$boxShadow: 0 3px 10px rgba(48, 52, 101, 0.07);
$deepBoxShadow: 0 16px 20px rgba(36, 85, 158, 0.08);

$primaryColor: $deepPurple;
$primaryColorLight: $deepPurpleLight;
